import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import ChitGenerator from "./components/ChitGenerator";
import Add from "./components/Add";
import Login from "./components/Login";
import { auth } from "./firebase"; // Import the Firebase auth instance
import { signOut, onAuthStateChanged } from "firebase/auth";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Manage login state
  const [loading, setLoading] = useState(true); // Track loading state

  // Check if the user is already logged in when the app starts
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsLoggedIn(true); // User is logged in
      } else {
        setIsLoggedIn(false); // No user is logged in
      }
      setLoading(false); // Loading is complete
    });

    // Cleanup the subscription to avoid memory leaks
    return () => unsubscribe();
  }, []);

  const handleLogout = async () => {
    try {
      await signOut(auth); // Sign out the user
      setIsLoggedIn(false); // Set login state to false
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  if (loading) {
    return <div>Loading...</div>; // Show loading state while checking auth
  }

  return (
    <Router>
      <Routes>
        {/* If logged in, route to main app components, else go to /login */}
        {!isLoggedIn ? (
          <>
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<Navigate to="/login" />} />
          </>
        ) : (
          <>
            <Route
              path="/"
              element={
                <>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <button
                      style={{
                        backgroundColor: "red",
                        width: "10%",
                      }}
                      onClick={handleLogout}
                    >
                      Logout
                    </button>
                  </div>

                  {/* Logout button */}
                  <Add />
                  <ChitGenerator />
                </>
              }
            />
            {/* Any other route should redirect to home page */}
            <Route path="*" element={<Navigate to="/" />} />
          </>
        )}
      </Routes>
    </Router>
  );
}

export default App;
