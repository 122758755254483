import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database"; // Change from firestore to database
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDZeVCOLfBz3YeY3sU2Bh0sG0Sp_IdKALM",
  authDomain: "chit-ed235.firebaseapp.com",
  databaseURL: "https://chit-ed235-default-rtdb.firebaseio.com", // Add your database URL
  projectId: "chit-ed235",
  storageBucket: "chit-ed235.appspot.com",
  messagingSenderId: "128017701806",
  appId: "1:128017701806:web:10e2df12072534215dc63f",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

export const database = getDatabase(app); // Export the database
