import React, { useRef, useState, useEffect } from "react";
import { database } from "../firebase"; // Import the database
import { ref, set, onValue, get } from "firebase/database"; // Import necessary functions
import "./Add.css"; // Ensure the CSS is linked properly

// Convert number to Roman numeral
const convertToRoman = (num) => {
  if (isNaN(num) || num <= 0) return "";
  const romanNumerals = [
    { value: 1000, symbol: "M" },
    { value: 900, symbol: "CM" },
    { value: 500, symbol: "D" },
    { value: 400, symbol: "CD" },
    { value: 100, symbol: "C" },
    { value: 90, symbol: "XC" },
    { value: 50, symbol: "L" },
    { value: 40, symbol: "XL" },
    { value: 10, symbol: "X" },
    { value: 9, symbol: "IX" },
    { value: 5, symbol: "V" },
    { value: 4, symbol: "IV" },
    { value: 1, symbol: "I" },
  ];

  let roman = "";
  for (const { value, symbol } of romanNumerals) {
    while (num >= value) {
      roman += symbol;
      num -= value;
    }
  }
  return roman;
};

export default function Add() {
  const numberRef = useRef();
  const chitSelectRef = useRef(); // New ref for chit series selection
  const nameRef = useRef(); // Separate ref for the name input
  const [chitSeries, setChitSeries] = useState([]);
  const chitSeriesRef = ref(database, "ChitSeries");

  // Load existing Chit Series from Firebase
  useEffect(() => {
    const unsubscribe = onValue(chitSeriesRef, (snapshot) => {
      const data = snapshot.val();
      const chitArray = data
        ? Object.entries(data).map(([key, value]) => ({
            id: key,
            chit: value.chit,
          }))
        : [];
      setChitSeries(chitArray);
    });

    return () => unsubscribe(); // Clean up the listener
  }, [chitSeriesRef]);

  const handleChitSave = async (e) => {
    e.preventDefault();
    const numberValue = parseInt(numberRef.current.value.trim(), 10);

    if (!numberValue || numberValue <= 0) return;

    const romanValue = convertToRoman(numberValue);

    try {
      // Reference to check for existing chit series
      const existingChitRef = ref(database, `ChitSeries/${romanValue}`);

      // Use get to check if the chit series already exists
      const snapshot = await get(existingChitRef);

      if (snapshot.exists()) {
        alert("Chit series already exists!"); // Alert if exists
        return; // Exit if the chit series already exists
      }

      const newChitRef = ref(database, `ChitSeries/${romanValue}`);
      await set(newChitRef, { chit: romanValue });
      numberRef.current.value = ""; // Clear input after saving
    } catch (e) {
      console.log(e);
    }
  };

  // Save a new name under the selected chit series
  const handleNameSave = async (e) => {
    e.preventDefault();
    const selectedChit = chitSelectRef.current.value; // Correctly get the selected chit series
    const nameValue = nameRef.current.value.trim(); // Entered name

    if (!selectedChit || !nameValue) return; // Validation check

    try {
      // Reference to the ChitNames node under the selected chit
      const chitNamesRef = ref(
        database,
        `ChitSeries/${selectedChit}/ChitNames`
      );

      let newIndex = 1; // Default index if none exists

      // Get the current ChitNames data to determine the next available index
      await onValue(
        chitNamesRef,
        (snapshot) => {
          const data = snapshot.val();
          if (data) {
            const keys = Object.keys(data); // Get all existing indices
            newIndex = keys.length + 1; // Determine the next index
          }
        },
        { onlyOnce: true }
      );

      // Create a reference to save the new name under the next index
      const newNameRef = ref(
        database,
        `ChitSeries/${selectedChit}/ChitNames/${newIndex}`
      );

      // Save the name as an object
      await set(newNameRef, { name: nameValue });

      nameRef.current.value = ""; // Clear input after saving
    } catch (e) {
      console.log(e); // Log errors if any
    }
  };

  return (
    <div className="add">
      <h2 className="heading2">Add Chit Series</h2>
      <form onSubmit={handleChitSave} className="formSaveChit">
        <label className="labelName">Enter a Number:</label>
        <input className="input" type="number" ref={numberRef} />
        <button type="submit">Save Chit Series</button>
      </form>

      <h2 className="heading2">Insert Name Under Chit Series</h2>
      <form onSubmit={handleNameSave} className="formSaveName">
        <label className="labelName">Select Chit Series:</label>
        <select ref={chitSelectRef}>
          {" "}
          {/* Use the correct ref */}
          <option value="">Select...</option>
          {chitSeries.map((series) => (
            <option key={series.id} value={series.chit}>
              {series.chit}
            </option>
          ))}
        </select>
        <label className="labelName">Name:</label>
        <input className="input" type="text" ref={nameRef} />{" "}
        {/* Separate ref for name */}
        <button type="submit">Save Name</button>
      </form>
    </div>
  );
}
