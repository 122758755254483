import React, { useState, useEffect } from "react";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { database } from "../firebase"; // Import the database
import { ref, onValue } from "firebase/database"; // Import necessary functions
import "./ChitGenerator.css";

const ChitGenerator = () => {
  // State for the form fields
  const [formData, setFormData] = useState({
    name: "",
    date: "",
    auctionDate: "",
    subscription: 0,
    auctionTime: "12:30", // Default time
    dividend: 0,
    total: 0,
    chitValue: 0,
    installmentNumber: 0,
    totalInstallments: 0,
  });

  const [chitSeries, setChitSeries] = useState([]); // State for chit series
  const [chitNames, setChitNames] = useState([]); // State for chit names
  const [selectedChitSeries, setSelectedChitSeries] = useState(""); // State for selected chit series

  // Fetch Chit Series from Firebase
  useEffect(() => {
    const chitSeriesRef = ref(database, "ChitSeries");
    const unsubscribe = onValue(chitSeriesRef, (snapshot) => {
      const data = snapshot.val();
      const chitArray = data
        ? Object.entries(data).map(([key, value]) => ({
            id: key,
            chit: value.chit,
          }))
        : [];
      setChitSeries(chitArray);
    });

    return () => unsubscribe(); // Clean up the listener
  }, []);

  // Fetch Chit Names when a Chit Series is selected
  useEffect(() => {
    if (selectedChitSeries) {
      const chitNamesRef = ref(
        database,
        `ChitSeries/${selectedChitSeries}/ChitNames`
      );
      const unsubscribe = onValue(chitNamesRef, (snapshot) => {
        const data = snapshot.val();
        const namesArray = data
          ? Object.entries(data).map(([key, value]) => ({
              id: key,
              name: value.name, // Adjust the field name if necessary
            }))
          : [];
        setChitNames(namesArray);
      });

      return () => unsubscribe(); // Clean up the listener
    }
  }, [selectedChitSeries]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Ensure the value is parsed as a float or set to 0 if invalid
    const newValue = parseFloat(value) || 0;

    // Always directly read from the form fields to get the latest values
    const currentSubscription =
      name === "subscription"
        ? newValue
        : parseFloat(formData.subscription) || 0;
    const currentDividend =
      name === "dividend" ? newValue : parseFloat(formData.dividend) || 0;

    // Calculate the total using current input values
    const total = currentSubscription - currentDividend;

    // Update the form data with the current input and calculated total
    setFormData({
      ...formData,
      [name]: value, // Update the specific field being changed
      total: isNaN(total) ? 0 : total, // Recalculate total based on the most recent values
    });
  };

  // Function to handle Chit Series selection
  const handleChitSeriesChange = (e) => {
    const { value } = e.target;
    setSelectedChitSeries(value);
    setFormData((prevData) => ({
      ...prevData,
      chitNo: "0", // Reset chitNo when changing chit series
    }));
  };

  // Function to calculate the total
  const calculateTotal = () => {
    // Convert the formData subscription and dividend to numbers
    const subscription = parseFloat(formData.subscription) || 0;
    const dividend = parseFloat(formData.dividend) || 0;

    console.log("Subscription:", subscription);
    console.log("Dividend:", dividend);

    // Perform the correct subtraction
    const total = subscription - dividend;

    setFormData((prevData) => ({
      ...prevData,
      total: isNaN(total) ? 0 : total,
    }));
  };

  // Function to convert numbers to words
  const convertNumberToWords = (number) => {
    const words = [
      "Zero",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
      "Ten",
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
      "Hundred",
      "Thousand",
    ];

    if (number < 20) return words[number];
    if (number < 100)
      return (
        words[20 + Math.floor(number / 10) - 2] +
        (number % 10 === 0 ? "" : " " + words[number % 10])
      );
    if (number < 1000)
      return (
        words[Math.floor(number / 100)] +
        " Hundred" +
        (number % 100 === 0 ? "" : " and " + convertNumberToWords(number % 100))
      );
    if (number < 1000000)
      return (
        convertNumberToWords(Math.floor(number / 1000)) +
        " Thousand" +
        (number % 1000 === 0 ? "" : " " + convertNumberToWords(number % 1000))
      );
    return "";
  };

  // Function to print chit as PDF
  const printChit = () => {
    console.log(document.body.innerHTML); // Inspect the current body content
    const chitOutputs = document.querySelectorAll(".chit-container"); // Select all chit containers
    console.log(`Found ${chitOutputs.length} chit outputs.`); // Check number of found outputs

    if (chitOutputs.length === 0) {
      console.error("No chits found to print.");
      return; // Exit the function if no chits are found
    }

    const pdf = new jsPDF("portrait", "pt", "a4");
    const pdfWidth = 595.28; // A4 width in points (pt)
    const pdfHeight = 841.89; // A4 height in points (pt)
    const topPadding = 25; // Padding from the top of the page
    const chitGap = 27; // Gap between chits
    const maxChitsPerPage = 3; // Maximum chits per page
    const scaleFactor = 0.85; // Scale down image size to 80%

    let currentHeight = topPadding; // Start printing from the top of the page
    let chitCount = 0; // Count of chits added to the current page
    let promises = []; // Array to hold promises for each canvas

    chitOutputs.forEach((chitOutput, index) => {
      promises.push(
        html2canvas(chitOutput, { scale: 2 }).then((canvas) => {
          const imgData = canvas.toDataURL("image/png");

          // Calculate the scaled image dimensions to fit within the A4 width
          const scaledWidth = pdfWidth * scaleFactor; // Scale down width
          const scaledHeight = (canvas.height * scaledWidth) / canvas.width; // Scale height accordingly

          const xOffset = (pdfWidth - scaledWidth) / 2; // Center the chit horizontally

          // If the chit doesn't fit on the current page, add a new page
          if (
            currentHeight + scaledHeight > pdfHeight ||
            chitCount >= maxChitsPerPage
          ) {
            pdf.addPage();
            currentHeight = topPadding; // Reset height to the top of the new page
            chitCount = 0; // Reset chit count for the new page
          }

          // Add the chit image to the PDF at the current position
          pdf.addImage(
            imgData,
            "PNG",
            xOffset, // X position (centered)
            currentHeight, // Y position (current height with padding)
            scaledWidth, // Image width
            scaledHeight // Image height
          );

          // Update the height to account for the chit and the gap
          currentHeight += scaledHeight + chitGap;
          chitCount++; // Increment chit count
        })
      );
    });

    // Wait for all canvases to be processed
    Promise.all(promises)
      .then(() => {
        pdf.save("chits.pdf");
      })
      .catch((error) => {
        console.error("Error generating PDF:", error);
      });
  };

  const selectedSeries = chitSeries.find(
    (series) => series.id === selectedChitSeries
  );
  const formatNumber = (number) => {
    // Handle undefined or null
    if (typeof number === "undefined" || number === null) return "0.00";

    // Convert to number to ensure it's a finite number
    const parsedNumber = parseFloat(number);

    // Check if it's a valid number
    if (isNaN(parsedNumber) || !isFinite(parsedNumber)) return "0.00";

    // Ensure two decimal places
    const strNum = parsedNumber.toFixed(2); // Convert to string with two decimals
    const [integerPart, decimalPart] = strNum.split("."); // Split into integer and decimal parts

    const lastThreeDigits = integerPart.slice(-3); // Get last three digits
    const otherDigits = integerPart.slice(0, -3); // Get the remaining digits

    // Use regex to group in Indian format
    const formattedOtherDigits = otherDigits
      ? otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," // Add commas after every two digits
      : "";

    return formattedOtherDigits + lastThreeDigits + "." + decimalPart; // Combine groups with decimal
  };

  return (
    <div>
      <h2>Chit Generator</h2>
      <div className="form-container">
        <h3>Enter Chit Details</h3>

        {/* Radio buttons for Chit Series */}
        <div className="form-row">
          <label>Select Chit Series:</label>
          {chitSeries.map((series) => (
            <div key={series.id} className="radioblock">
              <input
                type="radio"
                id={series.id}
                name="chitSeries"
                value={series.id} // Store the ID of the chit series
                onChange={handleChitSeriesChange} // Update selected series
                className="radioInput"
              />
              <label htmlFor={series.id}>{series.chit}</label>
            </div>
          ))}
        </div>

        {/* <div className="form-row">
          <label htmlFor="chitNo">Chit No:</label>
          <input
            type="text"
            id="chitNo"
            name="chitNo"
            value={formData.chitNo}
            onChange={handleInputChange}
          />
        </div> */}
        <div className="form-row">
          <label htmlFor="date">Date:</label>
          <input
            type="date"
            id="date"
            name="date"
            value={formData.date}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-row">
          <label htmlFor="auctionDate">Auction Date:</label>
          <input
            type="date"
            id="auctionDate"
            name="auctionDate"
            value={formData.auctionDate}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-row">
          <label htmlFor="auctionTime">Auction Time:</label>
          <input
            type="time"
            id="auctionTime"
            name="auctionTime"
            value={formData.auctionTime}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-row">
          <label htmlFor="installmentNumber">Current Installment No:</label>
          <input
            type="number"
            id="installmentNumber"
            name="installmentNumber"
            value={formData.installmentNumber}
            onChange={handleInputChange}
            min="1"
          />
        </div>

        <div className="form-row">
          <label htmlFor="totalInstallments">Total No. of Installments:</label>
          <input
            type="number"
            id="totalInstallments"
            name="totalInstallments"
            value={formData.totalInstallments}
            onChange={handleInputChange}
            min="1"
          />
        </div>
        <div className="form-row">
          <label htmlFor="chitValue">Chit Value</label>
          <input
            type="number"
            id="chitValue"
            name="chitValue"
            value={formData.chitValue}
            onChange={handleInputChange}
            min="1"
          />
        </div>
        <div className="form-row">
          <label htmlFor="subscription">Subscription Rs:</label>
          <input
            type="number"
            id="subscription"
            name="subscription"
            value={formData.subscription}
            onChange={handleInputChange} // Call handleInputChange only
            step="1" // Ensure step is correct for integer values
          />
        </div>
        <div className="form-row">
          <label htmlFor="dividend">Dividend Rs:</label>
          <input
            type="number"
            id="dividend"
            name="dividend"
            value={formData.dividend}
            onChange={handleInputChange} // Call handleInputChange only
            step="1" // Ensure step is correct for integer values
          />
        </div>

        {/* Button to calculate the total
        <button onClick={calculateTotal}>Calculate Total</button> */}

        <div className="form-row">
          <h4>Total Rs: {formData.total}</h4>
          <h4>Total in Words: {convertNumberToWords(formData.total)}</h4>
        </div>

        <div className="generate-chits">
          <button onClick={printChit}>Print Chit as PDF</button>
        </div>
      </div>

      {/* Chit Preview */}
      <div id="chit-output">
        {chitNames.map((chit, index) => (
          <div className="chit-container" key={chit.id}>
            <div className="chit-top-row">
              <div className="border-top-row company_name">
                <div
                  style={{
                    marginBottom: "2%",
                    textAlign: "end",
                    fontSize: "15px",
                  }}
                >
                  <b>Cell:</b> 9866013248
                </div>
                <div className="highlight">SRI RAJA RAJESHWARI CHITS</div>
                <div
                  style={{
                    marginTop: "2%",
                    textAlign: "center",
                    fontSize: "15px",
                  }}
                >
                  # 3-10-161, Reddy Colony, Road No:2, Hanamkonda.
                </div>
              </div>
              <div className="border-top-row" style={{ width: "15%" }}>
                Monthly Chit
                <br />
                Receipt No{" "}
                <b>{`${new Date().getFullYear().toString().slice(2)}${String(
                  new Date().getMonth() + 1
                ).padStart(2, "0")}${String(new Date().getDate()).padStart(
                  2,
                  "0"
                )}${selectedChitSeries}${index + 1}`}</b>
                {/* Using index as chit number */}
              </div>
              <div className="border-top-row-last">
                <div>Date: {formData.date}</div>
                <div>Auction Date: {formData.auctionDate}</div>
                <div>Auction Time: {formData.auctionTime}</div>
              </div>
            </div>

            <div className="chit-details-row">
              <div style={{ flex: "1" }} className="chit-details-left">
                <div>
                  Chit Name/Series:
                  <b>
                    {" "}
                    {selectedSeries ? selectedSeries.chit : "None Selected"}
                  </b>
                </div>
                <div>
                  Chit No: <b>{index + 1}</b>
                </div>{" "}
                {/* Display respective chit number */}
                <div>
                  Current Inst No:{" "}
                  <b>
                    {formData.installmentNumber} / {formData.totalInstallments}
                  </b>
                </div>
                <div>
                  Chit Value Rs: <b>{formatNumber(formData.chitValue)}</b>
                </div>
              </div>
              <div style={{ flex: "3" }} className="chit-details-right">
                <div>
                  Received with thanks from: <b>{chit.name}</b>
                </div>
                <table>
                  <thead>
                    <tr>
                      <th>Subscription</th>
                      <th>Dividend</th>
                      <th>Others</th>
                      <th>Penalty</th>
                      <th>Insp. Fee</th>
                      <th>Interest</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{formatNumber(formData.subscription)}</td>
                      <td>{formatNumber(formData.dividend)}</td>
                      <td>--</td>
                      <td>--</td>
                      <td>--</td>
                      <td>--</td>
                      <td>{formatNumber(formData.total)}</td>
                    </tr>
                  </tbody>
                </table>
                <div className="amount-in-words">
                  Rupees: {convertNumberToWords(formData.total)} Only
                </div>
              </div>
            </div>

            <div className="footer-columns">
              <div className="footer-left">
                Cheques Subjected to Realisation
              </div>
              <div className="footer-right">
                <div
                  style={{
                    textAlign: "right",
                    marginLeft: "100px",
                    marginRight: "-12%",
                  }}
                >
                  For: Sri Raja Rajeshwari Chits
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "10%",
                  }}
                >
                  <div style={{ marginLeft: "7px" }}>Cashier / Rec. Clerk</div>
                  <div style={{ marginRight: "-50%" }}>Foreman</div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChitGenerator;
